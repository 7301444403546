import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import DynamicComponent from '../components/DynamicComponent'

// GQL fragments
import '../fragments/HeroContentQuery'
import '../fragments/TextImageContentQuery'
import '../fragments/OneColContentQuery'
import '../fragments/TwoColsContentQuery'
import '../fragments/ThreeColsContentQuery'
import '../fragments/TextImageSquaresContentQuery'
import '../fragments/QuoteContentQuery'
import '../fragments/StaticImageVideoContentQuery'
import '../fragments/TemplateElementContentQuery'
import '../fragments/DivideContentQuery'
import '../fragments/LeveringstiderContentQuery'
import '../fragments/FormsContentQuery'
import '../fragments/DocumentsContentQuery'
import '../fragments/FaqContentQuery'

export default function ProductPage({ data }) {
  const pageInfo = data.craftEntryInterface

  const ogImageOverride = pageInfo.ogImage[0]?.ogimage
  const ogImageGlobal = data.craftHeaderGlobalSet.ogImage[0]?.ogimage

  const ogImage = ogImageOverride || ogImageGlobal
  return (
    <Layout
      pageTitle={pageInfo.title}
      pageDesc={pageInfo.metaDescription}
      ogImage={ogImage}
    >
      <DynamicComponent
        contentTypes={data.craftEntryInterface.dynamicContent}
        contentTypesCat={data?.craftCategoryInterface?.dynamicContent}
        inheritCategoryContent={data.craftEntryInterface.inheritCategoryContent}
        productSpecs={data.craftEntryInterface.specs}
        defaultSpecs={
          data.allCraftVinduerCategory?.nodes[0]?.specs ||
          data.allCraftDoreCategory?.nodes[0]?.specs ||
          data.allCraftGlasdoreCategory?.nodes[0]?.specs ||
          []
        }
        relatedAssets={data.allCraftDocumentsAsset.nodes}
        relatedOptions={data.allCraftOptionsCategory.nodes}
        relatedDesigns={data.allCraftDesignsCategory.nodes}
        productCarousel={data.craftEntryInterface.carouselContent}
        bild={data.craftEntryInterface.bild}
        externalLinks={data.craftEntryInterface.externalLinks}
      />
    </Layout>
  )
}

export const query = graphql`
  fragment SPECS on Craft_specs_specs_BlockType {
    heading
    table {
      data
      name
    }
  }
  fragment Carousel on Craft_carouselContent_item_BlockType {
    id
    heading
    text
    bild {
      title
      ... on Craft_productImages_Asset {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(height: 390, layout: FIXED)
          }
        }
      }
    }
  }
  query productPageQuery(
    $id: ID
    $parentId: ID
    $slug: String!
    $typeHandle: String!
    $category: String
  ) {
    craftHeaderGlobalSet {
      ogImage {
        ogimage
      }
    }
    allCraftDesignsCategory(
      filter: { entries: { elemMatch: { remoteId: { in: [$id, $parentId] } } } }
    ) {
      nodes {
        title
        column1
        productImage {
          ... on Craft_documents_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allCraftDocumentsAsset(
      sort: { fields: [title, extension], order: [ASC, DESC] }
      filter: { entries: { elemMatch: { remoteId: { in: [$id, $parentId] } } } }
    ) {
      nodes {
        filename
        url
        extension
        title
        folderId
      }
    }
    allCraftOptionsCategory(
      sort: { fields: lft, order: ASC }
      filter: {
        entries: { elemMatch: { remoteId: { in: [$id, $parentId] } } }
        enabled: { eq: true }
      }
    ) {
      nodes {
        title
        parent: remoteParent {
          title
        }
        productImage {
          title
          ... on Craft_productImages_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        colorPicker
        column1
      }
    }
    craftEntryInterface(slug: { eq: $slug }, typeHandle: { eq: $typeHandle }) {
      title
      slug
      typeHandle
      ... on Craft_produkter_vinduer_Entry {
        metaDescription
        ogImage {
          ogimage
        }
        bild {
          title
          ... on Craft_productImages_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        inheritCategoryContent
        dynamicContent {
          __typename
          ...HeroContent
          ...TextImageContent
          ...TextImageSquaresContent
          ...OneColContent
          ...TwoColsContent
          ...ThreeColsContent
          ...QuoteContent
          ...StaticImageVideoContent
          ...TemplateElementContent
          ...DocumentsContent
          ...LeveringstiderContent
          ...FaqContent
        }
        specs {
          ...SPECS
        }
        carouselContent {
          ...Carousel
        }
        externalLinks {
          ... on Craft_externalLinks_bimLinks_BlockType {
            linkUrl
            linkTitle
            typeHandle
          }
          ... on Craft_externalLinks_externalLinks_BlockType {
            linkUrl
            linkTitle
            typeHandle
          }
        }
      }
      ... on Craft_produkter_dore_Entry {
        metaDescription
        ogImage {
          ogimage
        }
        bild {
          title
          ... on Craft_productImages_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        inheritCategoryContent
        dynamicContent {
          __typename
          ...HeroContent
          ...TextImageContent
          ...TextImageSquaresContent
          ...OneColContent
          ...TwoColsContent
          ...ThreeColsContent
          ...QuoteContent
          ...StaticImageVideoContent
          ...TemplateElementContent
          ...LeveringstiderContent
          ...FaqContent
        }
        specs {
          ...SPECS
        }
        carouselContent {
          ...Carousel
        }
        externalLinks {
          ... on Craft_externalLinks_bimLinks_BlockType {
            linkUrl
            linkTitle
          }
          ... on Craft_externalLinks_externalLinks_BlockType {
            linkUrl
            linkTitle
          }
        }
      }
      ... on Craft_produkter_glasdore_Entry {
        metaDescription
        ogImage {
          ogimage
        }
        bild {
          title
          ... on Craft_productImages_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        inheritCategoryContent
        dynamicContent {
          __typename
          ...HeroContent
          ...TextImageContent
          ...TextImageSquaresContent
          ...OneColContent
          ...TwoColsContent
          ...ThreeColsContent
          ...QuoteContent
          ...StaticImageVideoContent
          ...TemplateElementContent
          ...LeveringstiderContent
          ...FaqContent
        }
        specs {
          ...SPECS
        }
        carouselContent {
          ...Carousel
        }
        externalLinks {
          ... on Craft_externalLinks_bimLinks_BlockType {
            linkUrl
            linkTitle
          }
          ... on Craft_externalLinks_externalLinks_BlockType {
            linkUrl
            linkTitle
          }
        }
      }
    }
    allCraftVinduerCategory(
      filter: { slug: { eq: $category }, groupHandle: { eq: $typeHandle } }
    ) {
      nodes {
        slug
        specs {
          ...SPECS
        }
      }
    }
    allCraftDoreCategory(
      filter: { slug: { eq: $category }, groupHandle: { eq: $typeHandle } }
    ) {
      nodes {
        slug
        specs {
          ...SPECS
        }
      }
    }
    allCraftGlasdoreCategory(
      filter: { slug: { eq: $category }, groupHandle: { eq: $typeHandle } }
    ) {
      nodes {
        slug
        specs {
          ...SPECS
        }
      }
    }
    craftCategoryInterface(
      slug: { eq: $category }
      groupHandle: { eq: $typeHandle }
    ) {
      ... on Craft_vinduer_Category {
        dynamicContent {
          __typename
          ...HeroContent
          ...TextImageContent
          ...TextImageSquaresContent
          ...OneColContent
          ...TwoColsContent
          ...ThreeColsContent
          ...QuoteContent
          ...StaticImageVideoContent
          ...TemplateElementContent
          ...DivideContent
          ...FormsContent
          ...LeveringstiderContent
          ...FaqContent
        }
      }
      ... on Craft_glasdore_Category {
        dynamicContent {
          __typename
          ...HeroContent
          ...TextImageContent
          ...TextImageSquaresContent
          ...OneColContent
          ...TwoColsContent
          ...ThreeColsContent
          ...QuoteContent
          ...StaticImageVideoContent
          ...TemplateElementContent
          ...DivideContent
          ...FormsContent
          ...LeveringstiderContent
          ...FaqContent
        }
      }
      ... on Craft_dore_Category {
        dynamicContent {
          __typename
          ...HeroContent
          ...TextImageContent
          ...TextImageSquaresContent
          ...OneColContent
          ...TwoColsContent
          ...ThreeColsContent
          ...QuoteContent
          ...StaticImageVideoContent
          ...TemplateElementContent
          ...DivideContent
          ...FormsContent
          ...LeveringstiderContent
          ...FaqContent
        }
      }
    }
  }
`
